<template>
  <v-row>
    <div>
      <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="#1976d2"
        size="128"
        is-full-screen
      />
    </div>
    <v-snackbar v-model="showSnackBar" color="#000" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <!-- <v-col cols="12" lg="12">
      <v-card no-header>
        <template slot="header">
          <v-row>
            <v-col cols="12" md="3" class="py-2">
              <span>Purchase Details</span>
            </v-col>
          </v-row>
        </template> -->
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card>
          <v-card-title> Purchase Details </v-card-title>
          <div class="pa-6" v-if="purchase">
            <v-row>
              <v-col>
                <span class="item_title">Customer Name</span><br />
                <span class="item_value"
                  >{{ purchase.firstname }} {{ purchase.lastname }}</span
                >
              </v-col>
              <v-col>
                <span class="item_title">Order Date</span><br />
                <span class="item_value">{{
                  formatDate(purchase.init_date)
                }}</span>
              </v-col>
              <v-col>
                <span class="item_title">Phone</span><br />
                <span class="item_value" v-if="purchase.mobile">{{
                  purchase.mobile
                }}</span>
                <span v-else class="item_value">-</span>
              </v-col>
              <v-col>
                <span class="item_title">Email</span><br />
                <span class="item_value" v-if="purchase.email">{{
                  purchase.email
                }}</span>
                <span v-else class="item_value">-</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="item_title">Purchase Type</span><br />
                <span class="item_value" v-if="purchase.purchasetype">{{
                  purchase.purchasetype
                }}</span>
                <span v-else class="item_value">-</span>
              </v-col>
              <v-col>
                <span class="item_title">Discount</span><br />
                <span class="item_value" v-if="purchase.discount">{{
                  purchase.discount
                }}</span>
                <span v-else class="item_value">-</span>
              </v-col>
              <v-col>
                <span class="item_title">Total Amount</span><br />
                <span class="item_value" v-if="purchase.amount">{{
                  purchase.amount
                }}</span>
                <span v-else class="item_value">-</span>
              </v-col>
              <v-col>
                <span class="item_title">Payment Status</span><br />
                <span class="item_value" v-if="purchase.payment_status">{{
                  purchase.payment_status
                }}</span>
                <span v-else class="item_value">-</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12" sm="6" md="3">
                <span class="item_title">Payment Type</span><br />
                <span class="item_value" v-if="purchase.method">{{
                  purchase.method
                }}</span>
                <span v-else class="item_value">-</span>
              </v-col>
              <v-col xs="12" sm="6" md="3">
                <span class="item_title">Client Address</span><br />
                <span class="item_value" v-if="purchase.deliveryaddress">
                  <span v-if="purchase.deliveryaddress.streetAddress"
                    >{{ purchase.deliveryaddress.streetAddress }},
                  </span>
                  <span v-if="purchase.deliveryaddress.pincode"
                    >{{ purchase.deliveryaddress.pincode }},
                  </span>
                  <span v-if="purchase.deliveryaddress.city"
                    >{{ purchase.deliveryaddress.city }},
                  </span>
                  <span v-if="purchase.deliveryaddress.district"
                    >{{ purchase.deliveryaddress.district }},
                  </span>
                  <span v-if="purchase.deliveryaddress.state"
                    >{{ purchase.deliveryaddress.state }},
                  </span>
                  <span v-if="purchase.deliveryaddress.country">{{
                    purchase.deliveryaddress.country
                  }}</span>
                </span>
                <span v-else class="item_value">-</span>
              </v-col>
            </v-row>
            <v-row v-if="purchase.isPromocodeApplied == true">
              <v-col cols="12" v-if="purchase.isPromocodeApplied == true"
                ><span style="font-size: 20px; font-weight: bold; color: green"
                  >Promocode Applied Details</span
                ></v-col
              >
              <v-col xs="12" sm="6" md="3">
                <span class="item_title1">Promocode</span><br />
                <span
                  style="color: green; font-size: 13px; font-weight: bold"
                  v-if="purchase.promoCodeDetails"
                  >{{ purchase.promoCodeDetails.code }}</span
                >
                <span v-else class="item_value">-</span>
              </v-col>
              <v-col xs="12" sm="6" md="3">
                <span class="item_title1">Discount</span><br />
                <span
                  style="color: green; font-size: 13px; font-weight: bold"
                  v-if="purchase.promoCodeDetails"
                  >{{ purchase.promoCodeDetails.codeDiscount }} %</span
                >
                <span v-else class="item_value">-</span>
              </v-col>
              <v-col xs="12" sm="6" md="3">
                <span class="item_title1">Discount Amount</span><br />
                <span
                  style="color: green; font-size: 13px; font-weight: bold"
                  v-if="purchase.promoCodeDetails"
                  >{{ purchase.promoCodeDetails.codeMaxAmount }}
                </span>
                <span v-else class="item_value">-</span>
              </v-col>
              <v-col xs="12" sm="6" md="3">
                <span class="item_title1">Code Type</span><br />
                <span
                  style="color: green; font-size: 13px; font-weight: bold"
                  v-if="purchase.promoCodeDetails"
                  >{{ purchase.promoCodeDetails.codeType }}
                </span>
                <span v-else class="item_value">-</span>
              </v-col>
            </v-row>
            <v-row v-if="purchase.isCoinsUsed == true">
              <v-col cols="12"
                ><span style="font-size: 20px; font-weight: bold; color: green"
                  >Super Coins Added</span
                ></v-col
              >
              <v-col xs="12" sm="6" md="3">
                <span class="item_title1">Coins Spend</span><br />
                <span
                  style="color: green; font-size: 13px; font-weight: bold"
                  v-if="purchase.coinsSpend"
                  >{{ purchase.coinsSpend }}</span
                >
                <span v-else class="item_value">-</span>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <div style="padding-bottom: 10px">
          <v-card>
            <v-card-title>Product Details</v-card-title>
            <!-- <v-row>
              <v-col cols="12" md="5">
                <v-row> -->
            <!-- <v-col cols="12">
                    <span style="font-size: 12px; font-weight: 500"
                      >Delivery Date</span
                    >
                  </v-col> -->
            <!-- <v-col cols="12">
                    <v-form-datepicker
                      v-model="i.group.tracking.coureier.expecteddeliverydate"
                    ></v-form-datepicker>
                  </v-col> -->
            <!-- <v-col cols="11" class="pl-10">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Delivery Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col> -->
            <!-- <v-col cols="12" md="3">
                <v-row> -->
            <!-- <v-col cols="12">
                    <span style="font-size: 12px; font-weight: 500"
                      >Delivery Time</span
                    >
                  </v-col> -->
            <!-- <v-col cols="12">
                    <v-form-timepicker
                      v-model="i.group.tracking.coureier.expecteddeliverytime"
                      locale="en"
                    ></v-form-timepicker>
                  </v-col> -->
            <!-- <v-row>
                    <v-col cols="11">
                      <v-text-field
                        v-model="time"
                        label="Delivery Time"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-on:click="menu2 = true"
                      ></v-text-field>
                      <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <v-time-picker v-model="time3" full-width>
                          <v-btn text color="primary" @click="addTime()"
                            >save</v-btn
                          >
                        </v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row> -->
            <!-- </v-row>
              </v-col> -->
            <!-- <v-col
                v-if="item.deliverystatus"
                cols="12"
                md="5"
                justify-content-md-center
              >
                <v-form @submit.prevent="getData">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-select
                        v-model="item.deliverystatus"
                        :items="status"
                        item-text="text"
                        item-value="value"
                        label="Delivery Status"
                        size
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col v-else>-</v-col>
              <v-col cols="2" class="pl-10">
                <v-row style="padding-top: 15px">
                  <v-col cols="12">
                    <v-btn outlined size="sm" @click="changeStatus()"
                      >Submit</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row> -->
            <!-- </template> -->
            <div v-if="details">
              <v-data-table
                :headers="headers"
                :items="details"
                :search="search"
                :items-per-page="count"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.Productname`]="{ item }">
                  <span v-if="item.product.productname">{{
                    item.product.productname
                  }}</span>
                </template>
                <template v-slot:[`item.Productcode`]="{ item }">
                  <span v-if="item.product.productcode">{{
                    item.product.productcode
                  }}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-layout pt-3>
                    <v-flex>
                      <v-select
                        v-model="item.deliverystatus"
                        :items="status"
                        item-text="text"
                        item-value="value"
                        outlined
                        height="15"
                        @change="changeStatus(item)"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
                </template>
                <template v-slot:[`item.Seller`]="{ item }">
                  <span v-if="item.product.seller.organization">
                    {{ item.product.seller.organization }}</span
                  >
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.currenttype`]="{ item }">
                  <span v-if="item.product.currenttype">{{
                    item.product.currenttype
                  }}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.dealtype`]="{ item }">
                  <span v-if="item.product">
                    <v-img
                      contain
                      height="100px"
                      width="100px"
                      :src="baseURL + item.product.photos[0]"
                    >
                    </v-img>
                  </span>
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.Quantity`]="{ item }">
                  <span v-if="item.quantity">{{ item.quantity }}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.Price`]="{ item }">
                  <span v-if="item.price">{{ item.price }}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.tPrice`]="{ item }">
                  <span v-if="item.price">{{
                    item.price * item.quantity
                  }}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.Receipt`]="{ item }">
                  <a
                    target="_blank"
                    style="text-decoration: none !important"
                    :href="
                      ipURL +
                      '/Purchase/printreceipt?id=' +
                      $route.params.purchaseid +
                      '&productid=' +
                      item.product._id
                    "
                    class="downlink"
                    >Download Receipt</a
                  >
                  <!-- </span> -->
                </template>
                <template v-slot:[`item.charge`]="{ item }">
                  <span>{{ item.shipping }}</span>
                </template>
              </v-data-table>
            </div>
            <div v-else>-</div>
          </v-card>
        </div>
        <!-- <v-card>
          <v-card-title>Payment Receipts</v-card-title>
          <v-data-table
            :headers="receipt"
            :items="receipts"
            :search="search"
            :items-per-page="count"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item.Products="{ item }">
              <span v-for="(name, a) in item.product.length" :key="a">
                <span> {{ item.product[a].productname }}</span
                >,
              
              </span>
    
            </template>
            <template v-slot:item.Seller="{ item }">
              <span v-if="item.product[0].seller.firstname"
                >{{ item.product[0].seller.firstname }}
                {{ item.product[0].seller.lastname }}</span
              >
            </template>
            <template v-slot:item.Receipt="{ item }">
            
              <a
                target="_blank"
                :href="
                  'https://api.styloop.in/Purchase/printreceipt?id=' +
                  $route.params.purchaseid +
                  '&productid=' +
                  item.product[0]._id +
                  '&index=' +
                  item.index
                "
                class="downlink"
                >Download Receipt</a
              >
          
            </template>
          </v-data-table>
        </v-card> -->
      </v-flex>
    </v-layout>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  props: ["purchaseid"],
  data() {
    return {
      purchase: null,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      deliverydate: null,
      deliverytime: null,
      products: [],
      receipts: [],
      product: [],
      deliverystatus: [],
      productdetails: [],
      details: [],
      search: "",
      count: 10,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      time: null,
      time3: null,
      menu2: false,
      appLoading: false,
      ServerError: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
      headers: [
        { text: " Product Code", value: "Productcode", sortable: false },
        { text: " Product Name", value: "Productname", sortable: false },
        { text: "Seller", value: "Seller", sortable: false },
        { text: "Image", value: "dealtype", sortable: false },
        { text: "Quantity", value: "Quantity", sortable: false },
        { text: "Total Price", value: "tPrice", sortable: false },
        { text: "Receipt", value: "Receipt", sortable: false, width: "20%" },
        {
          text: "Delivery Status",
          value: "status",
          sortable: false,
          width: "20%",
        },
      ],
      receipt: [
        // { key: "accountcreateat", label: "Created on", sortable: false },
        { value: "Products", text: "Products", tdClass: "tdwidth" },
        { value: "Seller", text: "Seller " },
        { value: "Receipt", text: "Receipt" },
      ],
      status: [
        { value: "Placed", text: "Placed" },
        { value: "Cancelled", text: "Cancelled" },
        { value: "Shipped", text: "Shipped" },
        { value: "In Transit", text: "In Transit" },
        { value: "Delivered", text: "Delivered" },
        { value: "Return", text: "Return" },
      ],
      Status: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    addTime() {
      this.time = this.time3;
      this.menu2 = false;
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/info",
        params: {
          id: this.purchaseid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          // if (response.data.data.details.length > 0) {
          //   this.deliverystatus = response.data.data.details;
          //   this.details.push(this.deliverystatus);
          // }
          this.deliverystatus = response.data.data.details[0].deliverystatus;
          if (response.data.data.productdetails.length > 0) {
            this.productdetails = response.data.data.details;
            this.details = this.productdetails;
          }
          this.purchase = response.data.data;
          this.products = response.data.orderdata;
          this.getReceipt();
          this.userStatus = this.purchase.status;
        })
        .catch((e) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(e);
        });
    },
    getReceipt() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/Purchase/receiptgroup",
        params: {
          purchaseid: this.purchaseid,
          productid: this.products[0].group.productdetails[0].product._id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.receipts = response.data.data;
        })
        .catch((e) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(e);
        });
    },
    changeStatus(d) {
      var data = {};
      data["status"] = d.deliverystatus;
      data["productid"] = d.product._id;
      // data["expecteddeliverydate"] = this.date;
      data["id"] = this.purchaseid;
      this.visible = true;
      axios({
        method: "POST",
        url: "/purchase/deliverystatus",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showSnackBar = true;
            this.msg = response.data.msg;
          } else {
            this.getData();

            this.showSnackBar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((e) => {
          this.appLoading = false;
          this.showSnackBar = true;
          console.log(e);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
<style>
.item_title {
  font-weight: bold;
  font-size: 14px;
}
.item_title1 {
  font-weight: bold;
  font-size: 16px;
}
.item_value {
  font-size: 13px;
}
.tdwidth {
  width: 50% !important;
}
</style>
